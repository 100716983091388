import { ThemeProvider } from "@emotion/react";
import { createTheme, responsiveFontSizes } from "@mui/material";
import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loading from "./components/loading";
import GeneralContextProvider from "./context/GeneralContext";
import Authorize from "./middlewares/Authorize";
import { pageRoutes } from "./routes/Routes";

function App() {
  let theme = createTheme({
    palette: {
      primary: {
        main: "#3d734d",
      },
      secondary: {
        main: "#f44336",
      },
    },
  });
  theme = responsiveFontSizes(theme);
  return (
    <div className="App">
      <BrowserRouter>
        <GeneralContextProvider>
          <ThemeProvider theme={theme}>
            <Suspense fallback={<Loading loading={true} />}>
              <Routes>
                {pageRoutes.map((item, index) =>
                  item.isProtected ? (
                    <Route key={`routes-${index}`} element={<Authorize />}>
                      <Route path={item.link} element={<item.component />} />
                    </Route>
                  ) : (
                    <Route
                      path={item.link}
                      element={<item.component />}
                      key={`routes-${index}`}
                    />
                  )
                )}
              </Routes>
            </Suspense>
          </ThemeProvider>
        </GeneralContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
