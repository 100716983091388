import { lazy } from "react";

const Dashboard = lazy(() => import("../pages/Dashboard"));
const ForgotPasswordPage = lazy(() => import("../pages/ForgotPasswordPage"));
const IntegrasiQrGelang = lazy(() =>
  import("../pages/gelang/IntegrasiQrGelang")
);
const QrGelang = lazy(() => import("../pages/gelang/QrGelang"));
const ScanQrAndSaleAddress = lazy(() =>
  import("../pages/gelang/ScanQrAndSaleAddress")
);
const Ticket = lazy(() => import("../pages/gelang/Ticket"));
const LaporanDetailTransaksi = lazy(() =>
  import("../pages/laporan/LaporanDetailTransaksi")
);
const LaporanPembatalan = lazy(() =>
  import("../pages/laporan/LaporanPembatalan")
);
const LaporanPembayaran = lazy(() =>
  import("../pages/laporan/LaporanPembayaran")
);
const LaporanPengunjung = lazy(() =>
  import("../pages/laporan/LaporanPengunjung")
);
const LaporanPenjualanPerTiket = lazy(() =>
  import("../pages/laporan/LaporanPenjualanPerTiket")
);
const LaporanRekapKasir = lazy(() =>
  import("../pages/laporan/LaporanRekapKasir")
);
const LaporanRekapPendapatan = lazy(() =>
  import("../pages/laporan/LaporanRekapPendapatan")
);
const LaporanRiwayatTiket = lazy(() =>
  import("../pages/laporan/LaporanRiwayatTiket")
);
const LaporanTransaksi = lazy(() =>
  import("../pages/laporan/LaporanTransaksi")
);
const LaporanRekapTahunan = lazy(() =>
  import("../pages/laporan/LaporanRekapTahunan")
);
const LaporanDetailTahunan = lazy(() =>
  import("../pages/laporan/LaporanDetailTahunan")
);
const LaporanVolumeTahunan = lazy(() =>
  import("../pages/laporan/LaporanVolumeTahunan")
);
const LaporanIntegrasiGelang = lazy(() =>
  import("../pages/laporan/LaporanIntegrasi")
);
const Admin = lazy(() => import("../pages/Loginpage"));
const UnblockUser = lazy(() => import("../pages/user/UnblockUser"));
const ChangePassword = lazy(() => import("../pages/ChangePassword"));
const Homepage = lazy(() => import("../pages/Homepage"));
const IntegrasiQrGelangGroup = lazy(() =>
  import("../pages/gelang/IntegrasiQrGelangGroup")
);

export const pageRoutes = [
  { name: "Homepage", link: "/", component: Homepage, isProtected: true },
  {
    name: "Dashboard",
    link: "/dashboard",
    component: Dashboard,
    isProtected: true,
  },
  {
    name: "Laporan Transaksi",
    link: "/laporan-transaksi",
    component: LaporanTransaksi,
    isProtected: true,
  },
  {
    name: "Laporan Pembatalan",
    link: "/laporan-pembatalan",
    component: LaporanPembatalan,
    isProtected: true,
  },
  {
    name: "Laporan Pembatalan",
    link: "/laporan-detail-transaksi",
    component: LaporanDetailTransaksi,
    isProtected: true,
  },
  {
    name: "Laporan Pembayaran",
    link: "/laporan-pembayaran",
    component: LaporanPembayaran,
    isProtected: true,
  },
  {
    name: "Laporan Penjualan Per Tiket",
    link: "/laporan-penjualan-per-tiket",
    component: LaporanPenjualanPerTiket,
    isProtected: true,
  },
  {
    name: "Laporan Pengunjung",
    link: "/laporan-pengunjung",
    component: LaporanPengunjung,
    isProtected: true,
  },
  {
    name: "Laporan Riwayat Tiket",
    link: "/laporan-riwayat-tiket",
    component: LaporanRiwayatTiket,
    isProtected: true,
  },
  {
    name: "Laporan Rekap Pendapatan",
    link: "/laporan-rekap-pendapatan",
    component: LaporanRekapPendapatan,
    isProtected: true,
  },
  {
    name: "Laporan Rekap Kasir",
    link: "/laporan-rekap-kasir",
    component: LaporanRekapKasir,
    isProtected: true,
  },
  {
    name: "Laporan Rekap Tahunan",
    link: "/laporan-rekap-tahunan",
    component: LaporanRekapTahunan,
    isProtected: true,
  },
  {
    name: "Laporan Detail Tahunan",
    link: "/laporan-detail-tahunan",
    component: LaporanDetailTahunan,
    isProtected: true,
  },
  {
    name: "Laporan Volume Tahunan",
    link: "/laporan-volume-tahunan",
    component: LaporanVolumeTahunan,
    isProtected: true,
  },
  {
    name: "Laporan Integrasi Gelang",
    link: "/laporan-integrasi-gelang",
    component: LaporanIntegrasiGelang,
    isProtected: true,
  },
  { name: "Admin", link: "/admin", component: Admin, isProtected: false },
  {
    name: "Integrasi Qr Gelang Individu",
    link: "/integrasi-qr-gelang-individu",
    component: IntegrasiQrGelang,
    isProtected: false,
  },
  {
    name: "Integrasi Qr Gelang Group",
    link: "/integrasi-qr-gelang-group",
    component: IntegrasiQrGelangGroup,
    isProtected: false,
  },
  {
    name: "Qr Gelang",
    link: "/qr-gelang",
    component: QrGelang,
    isProtected: false,
  },
  {
    name: "Cek Gelang",
    link: "/cek-gelang",
    component: ScanQrAndSaleAddress,
    isProtected: false,
  },
  {
    name: "Ticket",
    link: "/ticket",
    component: Ticket,
    isProtected: false,
  },
  {
    name: "Forgot Password",
    link: "/forgot-password",
    component: ForgotPasswordPage,
    isProtected: false,
  },
  {
    name: "Unblock User",
    link: "/unblock-user",
    component: UnblockUser,
    isProtected: false,
  },
  {
    name: "Change Password",
    link: "/change-password",
    component: ChangePassword,
    isProtected: false,
  },
];
